<template>
  <div>
    <p class="description">作成するアカウントを選択してください</p>
    <ul class="selectList">
      <li class="selectList-item">
        <router-link class="selectList-btn" to="/user/input">
          <div class="selectList-btn-content">
            <p class="selectList-title">master</p>
            <p class="selectList-description">運営アカウントの作成</p>
          </div>
        </router-link>
      </li>
      <li class="selectList-item">
        <router-link class="selectList-btn" to="/user/input">
          <div class="selectList-btn-content">
            <p class="selectList-title">client</p>
            <p class="selectList-description">加盟店アカウントの作成</p>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      //   pageName: 'ユーザー',
    };
  },
  mounted: function() {
    this.$emit('footer-hide');
  },
};
</script>
